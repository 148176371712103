<template>
  <!--
    The legend and max-coordinates of a visualiser (use in combination with visualisers and svg-definitions).
  -->
  <div class="visualiserFrame">
    <div class="visualiserFrameWrapper">
      <span
        :style="`top: 20px; left: ${ positionX }%;`"
        class="top"
      >{{ Math.ceil(top) }}</span>
      <span
        v-if="displayAllAxes"
        :style="`left: 50%; bottom: -15px;`"
        class="top"
      >{{ Math.floor(bottom) }}</span>
      <span
        :style="`right: 0; top: ${ positionY }%;`"
        class="right"
      >{{ Math.ceil(right) }}</span>
      <span
        v-if="displayAllAxes"
        :style="`left: 0; top: ${ positionX }%;`"
        class="right"
      >{{ Math.floor(left) }}</span>
      <slot />
    </div>
    <!-- LEGEND -->
    <template v-if="showLegend">
      <span class="big mr-2">{{ $t('visualiserFrameComponent.legend') }}</span>
      <template v-for="(legend, index) in legends">
        <div
          :key="`legend-${ index }`"
          class="m-badge m-badge--brand m-badge--wide mr-2"
          :style="`background-color:${ legend.color }`"
        >
          <span>{{ $t(`visualiser.${ legend.name }`) }}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'VisualiserFrame',
  props: {
    left: {
      type: Number,
      required: false,
      default: -100
    },
    right: {
      type: Number,
      required: false,
      default: 100
    },
    top: {
      type: Number,
      required: false,
      default: 100
    },
    bottom: {
      type: Number,
      required: false,
      default: -100
    },
    positionX: {
      type: Number,
      required: false,
      default () {
        return 50
      }
    },
    positionY: {
      type: Number,
      required: false,
      default () {
        return 50
      }
    },
    showLegend: {
      type: Boolean,
      required: false,
      default () {
        return true;
      }
    },
    displayAllAxes: {
      type: Boolean,
      required: false,
      default () {
        return true;
      }
    },
    legends: {
      type: Array,
      required: false,
      default () {
        return [
          { name: "flowArea", color: "#00c0ef" },
        ];
      }
    }
  },
}
</script>

<style scoped>
.visualiserFrameWrapper {
  position: relative;
  padding: 25px 30px;
  overflow: hidden;
}
.top, .right {
  position: absolute;
  display: block;
}
.top {
  transform: translate(-50%, -100%);
}
.right {
  transform: translate(0, -75%);
}
span > span {
  width: 10px;
  height: 10px;
  display: inline-block;
}
</style>
